var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"winner-page-main",attrs:{"id":"winnerFormWrap"}},[(_vm.formExpired || _vm.alreadyRegistered || _vm.formDisabled || _vm.uploadedWinner === true)?_c('FormExpiredAndRegistered',{attrs:{"formExpired":_vm.formExpired,"alreadyRegistered":_vm.alreadyRegistered,"formDisabled":_vm.formDisabled,"winnerType":_vm.winnerType}}):_c('div',{staticClass:"winner-page__main-content"},[_c('CodeFillingWinnerformHero',{attrs:{"winnerType":_vm.winnerType,"needBtn":true}}),_c('div',{staticClass:"winner-page__codefilling-form-background second-background-inner page-block",class:{ 'congrat-background-space': _vm.uploadedWinner === true }},[_c('h2',{staticClass:"text-center text-uppercase text-white"},[_c('img',{staticClass:"img-fluid",attrs:{"src":(_vm.baseUrl + "/lang_images/data-h2_" + (_vm.$store.state.currentLang) + ".svg"),"width":""}})]),_c('p',{staticClass:"codeFillingText__lead"},[_vm._v(_vm._s(_vm.tr('winner-sub-title')))]),_c('div',{staticClass:"container-xl"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-lg-10 offset-lg-1"},[_c('div',{staticClass:"codefilling-form"},[(_vm.formStatus === false)?_c('validation-observer',{ref:"winnerFormObserver",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form form--codefilling",attrs:{"id":"winnerForm"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.formSubmit)}}},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"winner-codefilling__form"},[_c('h3',{staticClass:"mt-0"},[_vm._v(_vm._s(_vm.tr('winning-block-title-1')))]),_c('div',{staticClass:"form-group form-row"},[_c('div',{staticClass:"surname-row input--info"},[_c('div',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.tr('winning-form-vezeteknev')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.surname),expression:"form.surname"}],staticClass:"form-control",attrs:{"type":"text","name":"Vezetéknév","id":"surname","disabled":""},domProps:{"value":(_vm.form.surname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "surname", $event.target.value)}}})]),_c('div',{staticClass:"forname-row input--info"},[_c('div',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.tr('winning-form-keresztnev')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.forename),expression:"form.forename"}],staticClass:"form-control",attrs:{"type":"text","name":"Keresztnév","id":"forename","disabled":""},domProps:{"value":(_vm.form.forename)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "forename", $event.target.value)}}})])]),_c('div',{staticClass:"form-group mb-0 form-row"},[_c('div',{staticClass:"form-group input--info phone-row"},[_c('ValidationProvider',{attrs:{"rules":"required","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-itemx-center"},[_c('div',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.tr('winning-form-phone')))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone),expression:"form.phone"}],staticClass:"form-control",attrs:{"type":"text","name":"phone","id":"phone","placeholder":_vm.tr('main-form-phone-placeholder')},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phone", $event.target.value)}}}),(_vm.getErrorMessage('phone', Object.entries(failedRules)[0]))?_c('span',{staticClass:"error hide"},[_vm._v(" "+_vm._s(_vm.getErrorMessage("phone", Object.entries(failedRules)[0]))+" ")]):_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"email-row input--info"},[_c('div',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.tr('winning-form-email')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",attrs:{"type":"text","name":"E-mail cím","id":"email","placeholder":"pl. tothlaszlo@mail.com","disabled":""},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}})])]),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.tr('winning-block-title-2')))]),_c('div',{staticClass:"form-group mb-0 form-row"},[_c('div',{staticClass:" form-group zip-row input--info"},[(_vm.$store.state.currentLang == 'hu')?_c('ValidationProvider',{attrs:{"rules":{required: true,  min: { length: 4 }  },"vid":"zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-itemx-center"},[_c('div',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.tr('winning-form-zip')))])]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"},{name:"model",rawName:"v-model",value:(_vm.form.zip),expression:"form.zip"}],staticClass:"form-control",attrs:{"type":"text","name":"zip","id":"zip","placeholder":_vm.tr('winning-form-zip-placeholder')},domProps:{"value":(_vm.form.zip)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "zip", $event.target.value)}}}),(_vm.getErrorMessage('zip', Object.entries(failedRules)[0]))?_c('span',{staticClass:"error hide"},[_vm._v(" "+_vm._s(_vm.getErrorMessage("zip", Object.entries(failedRules)[0]))+" ")]):_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_c('ValidationProvider',{attrs:{"rules":{required: true },"vid":"zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-itemx-center"},[_c('div',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.tr('winning-form-zip')))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.zip),expression:"form.zip"}],staticClass:"form-control",attrs:{"type":"text","name":"zip","id":"zip","placeholder":_vm.tr('winning-form-zip-placeholder')},domProps:{"value":(_vm.form.zip)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "zip", $event.target.value)}}}),(_vm.getErrorMessage('zip', Object.entries(failedRules)[0]))?_c('span',{staticClass:"error hide"},[_vm._v(" "+_vm._s(_vm.getErrorMessage("zip", Object.entries(failedRules)[0]))+" ")]):_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group input--info city-row"},[_c('ValidationProvider',{attrs:{"rules":{required: true },"vid":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-itemx-center"},[_c('div',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.tr('winning-form-city')))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.city),expression:"form.city"}],staticClass:"form-control",attrs:{"type":"text","name":"Város","id":"city","placeholder":_vm.tr('winning-form-city-placeholder')},domProps:{"value":(_vm.form.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "city", $event.target.value)}}}),(_vm.getErrorMessage('city', Object.entries(failedRules)[0]))?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.getErrorMessage("city", Object.entries(failedRules)[0]))+" ")]):_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group input--info form-row"},[_c('div',{staticClass:"input--info street-row"},[_c('ValidationProvider',{attrs:{"rules":{required:true },"vid":"street"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-itemx-center"},[_c('div',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.tr('winning-form-street')))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.street),expression:"form.street"}],staticClass:"form-control",attrs:{"type":"text","name":"Utca","id":"street","placeholder":_vm.tr('winning-form-street-placeholder')},domProps:{"value":(_vm.form.street)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "street", $event.target.value)}}}),(_vm.getErrorMessage('street', Object.entries(failedRules)[0]))?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.getErrorMessage("street", Object.entries(failedRules)[0]))+" ")]):_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"input--info house-row"},[_c('ValidationProvider',{attrs:{"rules":{required: true },"vid":"house"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-itemx-center"},[_c('div',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.tr('winning-form-house')))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.house),expression:"form.house"}],staticClass:"form-control",attrs:{"type":"text","name":"house","id":"house","placeholder":"21"},domProps:{"value":(_vm.form.house)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "house", $event.target.value)}}}),(_vm.getErrorMessage('house', Object.entries(failedRules)[0]))?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.getErrorMessage("house", Object.entries(failedRules)[0]))+" ")]):_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group input--info form-row"},[_c('div',{staticClass:"input--info house-row"},[_c('div',{staticClass:"d-flex align-itemx-center"},[_c('div',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.tr('winning-form-comment')))])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.comment),expression:"form.comment"}],staticClass:"form-control",attrs:{"type":"text","name":"comment","id":"comment","placeholder":_vm.tr('winning-form-comment-placeholder')},domProps:{"value":(_vm.form.comment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "comment", $event.target.value)}}})]),(_vm.needTax)?_c('div',{staticClass:" form-group zip-row input--info"},[_c('ValidationProvider',{attrs:{"rules":{required: true},"vid":"tax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-itemx-center"},[_c('div',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.tr('winning-form-tax')))]),_c('div',{staticClass:"icon-info",attrs:{"data-toggle":"tooltip","data-placement":"top","title":_vm.tr('winning-form-tax-tooltip')}})]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('#############'),expression:"'#############'"},{name:"model",rawName:"v-model",value:(_vm.form.tax_number),expression:"form.tax_number"}],staticClass:"form-control",attrs:{"type":"text","name":"tax_number","id":"tax_number","placeholder":_vm.tr('winning-form-tax-placeholder')},domProps:{"value":(_vm.form.tax_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "tax_number", $event.target.value)}}}),(_vm.getErrorMessage('tax', Object.entries(failedRules)[0]))?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.getErrorMessage("tax", Object.entries(failedRules)[0]))+" ")]):_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.needBirth)?_c('div',{staticClass:" form-group zip-row input--info mt-3"},[_c('ValidationProvider',{attrs:{"rules":{required: true},"vid":"tax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-itemx-center"},[_c('div',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.tr('winning-form-birth')))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.birth_number),expression:"form.birth_number"}],staticClass:"form-control",attrs:{"type":"text","name":"birth_number","id":"birth_number"},domProps:{"value":(_vm.form.birth_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "birth_number", $event.target.value)}}}),(_vm.getErrorMessage('birth', Object.entries(failedRules)[0]))?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.getErrorMessage("birth", Object.entries(failedRules)[0]))+" ")]):_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()]),_c('div',{staticClass:"info-block mb-4"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/img/icons/ic-info.svg")}}),_vm._v(" "+_vm._s(_vm.tr('winning-form-info-text'))+" ")]),(_vm.$store.state.currentLang != 'hu')?_c('div',{staticClass:"info-block my-4"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/img/icons/ic-info.svg")}}),_vm._v(" "+_vm._s(_vm.tr('winning-form-info-text-second'))+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex justify-content-center mt-4"},[_c('button',{staticClass:"btn btn-primary mr-0 btn-primary--large",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.tr('winning-form-submit-text'))+" ")])])]),_c('div')])])])]}}],null,false,117540111)}):_vm._e()],1)])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }