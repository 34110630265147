<template>
  <div id="winner-page" class="winner-page">
    <div class="winner-page__content">      
      <CodeFillingWinnerForm/>
    </div>
  </div>
</template>

<script>
import CodeFillingWinnerForm from '@/components/CodeFillingWinner-form.vue'

export default {
  components: {
    CodeFillingWinnerForm    
  },
  mounted() {
    this.$store.dispatch("getFileUrl");
    this.$store.dispatch("getPrivacyUrl");
  }
}
</script>
